import React from "react";
import { useEffect } from "react";
import DepartmentHeader from "../components/DepartmentHeader";
import Navbar from "../layouts/Navbar";
import Breadcrumbs from "../components/buttons/Breadcrumbs";
import Footer from "../layouts/Footer";
import currentPageStore from "../utils/stores/currentPageStore";



function Calendar({ pageHeader }) {
    
    const setCurrentPage = currentPageStore((state) => state.setCurrentPage);

    useEffect(() => {
        setCurrentPage(pageHeader);
        // console.log(pageHeader)
    }, [pageHeader])
    return (

        <>
            <Navbar iD={1} />
            {/* // Tockify Calendar Application */}
            <div className="central-nav-container wrapper ">
                <Breadcrumbs />
                <DepartmentHeader header="Calendar of Events" />
                <div data-tockify-component="calendar" data-tockify-calendar="dbhdid"></div>
            </div>
            <Footer />
        </>

    )
}

export default Calendar;