import { create } from 'zustand';

//zustand: https://github.com/pmndrs/zustand
//Set the base URL to used by other comp.
//Comp examples being ussed: 
const aPIURLStore = create((set, get) => ({
    baseURL: '',
    setBaseURL: (value) => set({ baseURL: value })
})
);

export default  aPIURLStore 
