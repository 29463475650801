const bodyHelperTypes = {

    // Types of comp. on the page
    title: 1,
    paragraph: 2, 
    paragraphs: 3, 
    orderedList: 4, 
    unorderedList: 5,
    image: 6, 
    header: 7,
    links: 8,
};

export default bodyHelperTypes;