// Cleans the Comp.'s URL and checks if the request is from localhost, dbhdidtest, dbhdid.
// From localhost, you are not able to access the documents without this comp. 
const SanitizeHref = (href) => {
    // console.log(href)
    // Checks for null hrefs
    if(href == undefined || href == null){
        return ""; 
    }
    if (href.startsWith("http") || !href.includes("documents/")) {
        return href;
    }
    const testDomain = "https://dbhdidtest.ky.gov/";
    const domain = "https://dbhdid.ky.gov/";
    const windowUrl = window.location.href;
    /*Important to note: "localhost" and "dbhdidtest" access resources from the test server and not the production server.
    If you are making changes from to the production server make sure you are not on test.
    */
    if (windowUrl.includes("localhost") || windowUrl.includes("dbhdidtest")) {
        // \\VWSWEBA01BHDID\iis-sites\dbhdidtest.ky.gov
        return testDomain + href;
    } else {
        // \\VWSWEBP01BHDID\iis-sites\dbhdid.ky.gov
        return domain + href;
    }
};

export default SanitizeHref;