import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import getBreadCrumb from '../../utils/BreadcrumbMap';
// Css
import "../../assets/styles/pages/style.css";
import currentPageStore from '../../utils/stores/currentPageStore';
import SanitizeHref from '../../utils/SanitizeHref';


function Breadcrumbs() {
    
    const currentPage = currentPageStore((state) => state.currentPage);
    // Store the breadcrumb information
    const location =  useLocation();
    
    // console.log(location);
    // Keep track of the link of each "crumb"
    let currentLink = '';
    // store the pathname property and split the array with '/' (forward slash)
    const crumbs =  location.pathname.split('/')

        // filters out array element that's equal to an empty string
        .filter(crumb => crumb !==   '')
        // return output
        .map((crumb,index,{length}) => {
            // console.log(crumb)
            
            
            const isLast = length-1 == index;
            currentLink += `/${crumb}`
            return (
                <>         
                    <div className='crumb' key={crumb} >
                        {/* output Links to the page */}
                        <Link className="container button-nav breadcrumbs" to={SanitizeHref(currentLink)}>{isLast ? currentPage : getBreadCrumb(crumb)  }</Link>
                    </div>

                </>
            )
        })
    return (
        <>
            <div className='full-breadcrumb '>
                <ol class="breadcrumbs layout-crumb" >
                <Link to={"/"}>Home {' > '} </Link> <div></div> 
                    {crumbs}
                </ol>
                <div className=' '></div>


            </div>

        </>
    );
}

export default Breadcrumbs;