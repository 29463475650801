// Libs:
import React from 'react';
// css:
import "../../assets/styles/pages/style.css";
// Layout
import bodyHelperTypes from '../../utils/BodyHelperTypes';
import SectionTitle from './helpers/SectionTitle';
import Paragraph from './helpers/Paragraph';
import Paragraphs from './helpers/Paragraphs';
import OrderedList from './helpers/OrderedList';
import UnorderedList from './helpers/UnorderedList';
import Picture from './helpers/Picture';
import LinksTwo from '../../layouts/bodyLayouts/helpers/LinksTwo';


function BodyContent({ content }) {
    const contentElements = content.sort((a, b) => a.order - b.order).map((item, index) => {

        switch (item.format) {
            case bodyHelperTypes.title:
                return <SectionTitle key={index} text={item.content} />;
            case bodyHelperTypes.paragraph:
                return <Paragraph key={index} text={item.content} />;
            case bodyHelperTypes.paragraphs:
                return <Paragraphs key={index} items={item.content} />;
            case bodyHelperTypes.orderedList:
                return <OrderedList key={index} items={item.content} />;
            case bodyHelperTypes.unorderedList:
                return <UnorderedList key={index} items={item.content} />;
            case bodyHelperTypes.image:
                return <Picture key={index} item={item.content} />;      
            case bodyHelperTypes.links:
                return <LinksTwo key={index} linkTypeID={item.content} />;    
            default:
                throw Error("Invalid format");
        }

    });
    return (
         contentElements 
    );
};
export default BodyContent;