// Libs:
import React, { useEffect } from 'react';
// css:
import "../assets/styles/pages/style.css";
// Header
import DepartmentHeader from '../components/DepartmentHeader';
// Layout
import LayoutOne from '../layouts/bodyLayouts/LayoutOne';
import BodyContent from './bodyLayouts/BodyContent';
import currentPageStore from '../utils/stores/currentPageStore';
import Navbar from '../layouts/Navbar';
import Breadcrumbs from '../components/buttons/Breadcrumbs';
import ScrollToTop from '../components/ScrollToTop';
import Footer from './Footer';

// Most department/division pages will follow this format.
function MasterPage({ pageID, bodyContent, pageHeader }) {

    const setCurrentPage = currentPageStore((state) => state.setCurrentPage);

    useEffect(() => {
        setCurrentPage(pageHeader);
        // console.log(pageHeader)
    }, [pageHeader])
    return (
        <>
            <Navbar iD={pageID} />
            <div className=" central-nav-container wrapper">
                <article className="main">
                    <Breadcrumbs />
                    <DepartmentHeader header={pageHeader} />
                    <div className='bodyText'>
                        <BodyContent content={bodyContent} />
                    </div>
                </article>
                {/* For Future Projects: Adding another Column */}
                {/* <aside className="aside aside-1">
                Test
                </aside> */}
                
                <div className='aside aside-2'>
                    <LayoutOne pageID={pageID} />
                </div>
            </div>
            <ScrollToTop />
            <Footer />
        </>
    );
};
export default MasterPage;